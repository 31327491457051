import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'sw-ui';

import { Button } from '../../../components/button';
import Input from '../../../components/input';

import { AIRLINE_ITEMS } from '../../../bi/constants/airline';
import COMPONENTS from '../../../bi/constants/components';
import AIRPROVIDERS from '../../../bi/constants/arline';

import styles from './styles.module.css';

const OFFICE_ID_ITEMS = AIRPROVIDERS.AMADEUS.ORDER_ID;

const LABELS = {
  HEADER: 'Добавить новую настройку',
  PROVIDER: 'Поставщик',
  OFFICE_ID: 'Office ID',
  EXCHANGE_RATES: {
    SOURCE: 'Источник курса валюты',
    HEADER: 'Курс валют',
    APPLY: 'Применить курс',
    SELECT_CURRENCY: 'Выберите валюту',
    CUSTOM_CURRENCY: 'Ручной ввод',
  },
  HIDDEN_SUPPLIER_FEE: {
    HEADER: 'Сбор поставщика',
    FIXED_HIDDEN_FEE_TICKET: 'Фиксированный сбор поставщика за билет, руб',
    FIXED_HIDDEN_FEE_SEGMENT: 'Фиксированный сбор поставщика за сегмент, руб',
    INTEREST_HIDDEN_FEE: 'Процентный сбор поставщика, %',
  },
  ADDITIONAL_FEE: {
    HEADER: 'Сбор за выписку в валюте',
    FIXED_HIDDEN_FEE_TICKET: 'Фиксированный сбор за выписку в валюте за билет, руб',
    FIXED_HIDDEN_FEE_SEGMENT: 'Фиксированный сбор за выписку в валюте за сегмент, руб',
    INTEREST_ADDITONAL_FEE: 'Процентный сбор за выписку в валюте, %',
  },
  BUTTONS: {
    SAVE: 'Сохранить',
    CLEAR: 'Очистить',
  },
};

const CalculatorAdditionalFee = ({
  providerData,
  setProviderData,
  hiddenFeeData,
  setHiddenFeeData,
  additionalFeeData,
  setAdditionalFeeData,
  handleSaveInfo,
  handleClear,
  validation,
  scrollRef,
}) => {
  const renderOfficeId = providerData.provider === AIRPROVIDERS.AMADEUS.TYPE ? (
    <Select
      className={ styles.select }
      value={ providerData.officeId }
      items={ OFFICE_ID_ITEMS }
      label={ LABELS.OFFICE_ID }
      theme={ COMPONENTS.SELECT.THEME.BORDER }
      onChange={ ({ value }) => setProviderData(prev => ({ ...prev, officeId: value })) }
    />
  ) : null;

  const renderValidationProviderError = validation.provider ? (
    <div className={ styles.validation }>{validation.provider}</div>
  ) : null;

  const renderValidationFeeError = validation.fee ? (
    <div className={ `${styles.validation} ${styles.validation_fee}` }>{validation.fee}</div>
  ) : null;

  return (
    <div className={ styles.container }>
      <div className={ styles.header }>
        { LABELS.HEADER }
      </div>
      <div className={ styles.wrapper } ref={ scrollRef }>
        <div className={ `${styles.provider} ${styles['col-1-3']}` }>
          <Select
            className={ styles.select }
            value={ providerData.provider }
            items={ AIRLINE_ITEMS }
            label={ LABELS.PROVIDER }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ ({ value }) => setProviderData(prev => ({ ...prev, provider: value })) }
          />
          { renderOfficeId }
          { renderValidationProviderError }
        </div>
        <div className={ `${styles['col-1-3']} ${styles.hidden_supplier}` }>
          <div className={ styles.header }>{ LABELS.HIDDEN_SUPPLIER_FEE.HEADER }</div>
          <Input
            type='number'
            className={ styles.interest_hidden_fee }
            value={ hiddenFeeData.forTicket }
            label={ LABELS.HIDDEN_SUPPLIER_FEE.FIXED_HIDDEN_FEE_TICKET }
            onChange={ ({ target: { value } }) => setHiddenFeeData(prev => ({ ...prev, forTicket: value })) }
          />
          <Input
            type='number'
            className={ styles.interest_hidden_fee }
            value={ hiddenFeeData.forSegment }
            label={ LABELS.HIDDEN_SUPPLIER_FEE.FIXED_HIDDEN_FEE_SEGMENT }
            onChange={ ({ target: { value } }) => setHiddenFeeData(prev => ({ ...prev, forSegment: value })) }
          />
          <Input
            type='number'
            className={ styles.interest_hidden_fee }
            value={ hiddenFeeData.percent }
            label={ LABELS.HIDDEN_SUPPLIER_FEE.INTEREST_HIDDEN_FEE }
            onChange={ ({ target: { value } }) => setHiddenFeeData(prev => ({ ...prev, percent: value })) }
          />
        </div>
        <div className={ `${styles['col-1-3']} ${styles.additional_fee}` }>
          <div className={ styles.header }>{ LABELS.ADDITIONAL_FEE.HEADER }</div>
          <Input
            type='number'
            className={ styles.interest_additional_fee }
            value={ additionalFeeData.forTicket }
            label={ LABELS.ADDITIONAL_FEE.FIXED_HIDDEN_FEE_TICKET }
            onChange={ ({ target: { value } }) => setAdditionalFeeData(prev => ({ ...prev, forTicket: value })) }
          />
          <Input
            type='number'
            className={ styles.interest_additional_fee }
            value={ additionalFeeData.forSegment }
            label={ LABELS.ADDITIONAL_FEE.FIXED_HIDDEN_FEE_SEGMENT }
            onChange={ ({ target: { value } }) => setAdditionalFeeData(prev => ({ ...prev, forSegment: value })) }
          />
          <Input
            type='number'
            className={ styles.interest_additional_fee }
            value={ additionalFeeData.percent }
            label={ LABELS.ADDITIONAL_FEE.INTEREST_ADDITONAL_FEE }
            onChange={ ({ target: { value } }) => setAdditionalFeeData(prev => ({ ...prev, percent: value })) }
          />
        </div>
      </div>
      { renderValidationFeeError }
      <div className={ styles.buttons_wrapper }>
        <Button
          onClick={ handleSaveInfo }
          label={ LABELS.BUTTONS.SAVE }
        />
        <Button
          className={ styles.button_clear }
          onClick={ handleClear }
          label={ LABELS.BUTTONS.CLEAR }
        />
      </div>
    </div>
  );
};

CalculatorAdditionalFee.propTypes = {
  providerData: PropTypes.object.isRequired,
  setProviderData: PropTypes.func.isRequired,
  additionalFeeData: PropTypes.object.isRequired,
  setAdditionalFeeData: PropTypes.func.isRequired,
  hiddenFeeData: PropTypes.object.isRequired,
  setHiddenFeeData: PropTypes.func.isRequired,
  handleSaveInfo: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  scrollRef: PropTypes.object.isRequired,
};

export { CalculatorAdditionalFee };

