import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'sw-ui';

import COMPONENTS from '../../bi/constants/components';
import styles from './styles/index.module.scss';

import { LINK_TEXT_KEYS, LINK_TEXT, LINK_ICONS, DEFAULT_NAME } from '../../bi/constants/calls';


const buildTextForLink = (isLink, key) => {
  const textActiveLink = LINK_TEXT.ACTIVE[key];
  return isLink ? textActiveLink : LINK_TEXT.DISABLED;
};

const buildLinkHtml = (link, key) => {
  const iconHtml = link ? (
    <a href={ link } className={ styles.link } target='_blank' rel='noreferrer'>
      <i className='material-icons'>{LINK_ICONS.ACTIVE}</i>
    </a>
  ) :
  (
    <div className={ styles.link_off } >
      <i className='material-icons'>{LINK_ICONS.DISABLED}</i>
    </div>
  );


  return (
    <span className='sw-tooltip-wrapper'>
      {iconHtml}
      <Tooltip
        position={ COMPONENTS.TOOLTIP.POSITION.TOP }
      >
        { buildTextForLink(link, key) }
      </Tooltip>
    </span>
  );
};

const CallsItem = ({ uId, time, phoneNumber, fullName, accountId, companyId, employeeId, orderNumber }) => {
  const employeeLink = employeeId && `/employeePage/${employeeId}`;
  const accountLink = accountId && companyId && `/account/${accountId}/company/${companyId}/account/profile`;
  const date = new Date(time).toLocaleTimeString();

  return (
    <ul className={ styles.table_row }>
      <li className={ `${styles.table_col} ${styles.col_1_9}` }>{orderNumber}</li>
      <li className={ `${styles.table_col} ${styles.col_1_9}` }>{uId}</li>
      <li className={ styles.table_col }>{date}</li>
      <li className={ styles.table_col }>{phoneNumber}</li>
      <li className={ `${styles.table_col} ${styles.col_1_5}` }>{fullName || DEFAULT_NAME}</li>
      <li className={ `${styles.table_col} ${styles.col_by_centr}` }>{buildLinkHtml(employeeLink, LINK_TEXT_KEYS.EMPLOYEE_KEY)}</li>
      <li className={ `${styles.table_col} ${styles.col_by_centr}` }>{buildLinkHtml(accountLink, LINK_TEXT_KEYS.ACCOUNT_KEY)}</li>
    </ul>
  );
};

CallsItem.propTypes = {
  uId: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  accountId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  employeeId: PropTypes.number.isRequired,
  orderNumber: PropTypes.number.isRequired,
};

export default CallsItem;
