import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../components/button';

import styles from './styles.module.css';

const LABELS = {
  HEADER: 'Заполненная информация',
  HEADERS: {
    PROVIDER_OFFICE_ID: 'Поставщик (OfficeId)',
    ADDITIONAL_FEE: 'Сбор за выписку в валюте',
    HIDDEN_FEE: 'Сбор поставщика',
  },
  HIDDEN_FEE: {
    FOR_TICKET: 'Фиксированный сбор поставщика за билет',
    FOR_SEGMENT: 'Фиксированный сбор поставщика за сегмент',
    PERCENT: 'Процентный сбор поставщика',
  },
  ADDITIONAL_FEE: {
    FOR_TICKET: 'Фиксированный сбор за выписку в валюте за билет',
    FOR_SEGMENT: 'Фиксированный сбор за выписку в валюте за сегмент',
    PERCENT: 'Процентный сбор за выписку в валюте',
  },
  BUTTONS: {
    EDIT: 'Изменить',
    DELETE: 'Удалить',
  },
};

const CalculatorAdditionalFeeInfo = ({ list, handleEditInfo, handleDeleteInfo }) => {
  const renderRowFee = (label, value) => (
    <div className={ `${styles.row} ${styles.feeRow}` }>
      <div>
        { label }
      </div>
      <div>
        { value }
      </div>
    </div>
  );

  const renderHiddenFee = ({ forTicket, forSegment, percent }) => (
    <div className={ styles['col-1-4'] }>
      { renderRowFee(LABELS.HIDDEN_FEE.FOR_TICKET, forTicket) }
      { renderRowFee(LABELS.HIDDEN_FEE.FOR_SEGMENT, forSegment) }
      { renderRowFee(LABELS.HIDDEN_FEE.PERCENT, percent) }
    </div>
  );

  const renderAdditionalFee = ({ forTicket, forSegment, percent }) => (
    <div className={ styles['col-1-4'] }>
      { renderRowFee(LABELS.ADDITIONAL_FEE.FOR_TICKET, forTicket) }
      { renderRowFee(LABELS.ADDITIONAL_FEE.FOR_SEGMENT, forSegment) }
      { renderRowFee(LABELS.ADDITIONAL_FEE.PERCENT, percent) }
    </div>
  );

  const renderRow = (item) => {
    const { providerData, hiddenFeeData, additionalFeeData } = item;
    const { provider, officeId } = providerData;

    const renderOfficeId = officeId ? <div>({ officeId })</div> : null;

    return (
      <div key={ `${provider}_${officeId}` } className={ `${styles.row} ${styles.border} ${styles.wrapper}` }>
        <div className={ `${styles['col-1-7']} ${styles.providerHeader}` }>
          <div>{ provider }</div>
          { renderOfficeId }
        </div>
        { renderHiddenFee(hiddenFeeData) }
        { renderAdditionalFee(additionalFeeData) }
        <div className={ `${styles['col-1-5']} ${styles.buttons} ${styles.row}` }>
          <Button
            onClick={ () => handleEditInfo(item) }
            label={ LABELS.BUTTONS.EDIT }
          />
          <Button
            className={ styles.button_delete }
            onClick={ () => handleDeleteInfo(item) }
            label={ LABELS.BUTTONS.DELETE }
          />
        </div>
      </div>
    );
  };

  const renderRows = () => list.map(item => renderRow(item));

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.header }>
        { LABELS.HEADER }
      </div>
      <div className={ `${styles.row} ${styles['row-header']}` }>
        <div className={ styles['col-1-7'] }>
          { LABELS.HEADERS.PROVIDER_OFFICE_ID }
        </div>
        <div className={ styles['col-1-4'] }>
          { LABELS.HEADERS.HIDDEN_FEE }
        </div>
        <div className={ styles['col-1-4'] }>
          { LABELS.HEADERS.ADDITIONAL_FEE }
        </div>
      </div>
      { renderRows() }
    </div>
  );
};

CalculatorAdditionalFeeInfo.propTypes = {
  list: PropTypes.array.isRequired,
  handleEditInfo: PropTypes.func.isRequired,
  handleDeleteInfo: PropTypes.func.isRequired,
};

export { CalculatorAdditionalFeeInfo };
