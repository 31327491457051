import React, { Component } from 'react';
import PropTypes from 'prop-types';

import bookTripItem from './item';

import styles from './styles/bookTrip.module.scss';

class BookTripList extends Component {
  static propTypes = {
    itemCompany: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    handleOpenCompany: PropTypes.func.isRequired,
  };

  handleOpenCompany = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this.props.handleOpenCompany(this.props.itemCompany);
  };

  render() {
    const {
      itemCompany,
      onClick,
    } = this.props;

    const ordersHtml = itemCompany.Items.map((item, key) => bookTripItem(key, item));

    const nameCompanyHtml = itemCompany.CompanyNames.map((name, ind) => (
      <a className={ styles.link } key={ ind } onClick={ this.handleOpenCompany }>
        { name }
      </a>
    ));

    return (
      <div className={ `${styles.row} ${styles.click}` } onMouseDown={ e => onClick(itemCompany, e) }>
        <div className={ styles['col-1-16'] }>
          { itemCompany.Id }
        </div>
        <div className={ styles['col-3-16'] }>
          { nameCompanyHtml }
        </div>
        <div className={ styles['col-3-4'] }>
          { ordersHtml }
        </div>
      </div>
    );
  }
}

export default BookTripList;
